.row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}

.row button {
  min-width: 100px;
}

.table {
  width: 100%;
}

.loading {
  min-height: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
